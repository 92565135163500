import React from 'react'

import "./Footer.css"

export default function Footer() {
  return (
    <div className='footer'>
        <h3>Made by <a href='https://joelprat.com' target='_blank'>JPV</a></h3>
    </div>
  )
}
