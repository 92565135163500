import React from 'react'

import "./Member.css"

export default function Member({ img, name, title, experience_years, description }) {
  return (
    <div className="member">
      <div className='vertical-align'>
        <img src={img} alt='personal optica santpedor' />
        <h3>{name}</h3>
        <p id='title'>{title}</p>
        <p id='exp_years'>{experience_years}</p>
      </div>
      <p id='description'>{description}</p>
    </div>
  )
}
