import ingrid from './assets/ingrid.jpeg'
import loinaz from './assets/loinaz.jpeg'


const texts = {
    CAT:{
        header:[
            "Inici",
            "Nosaltres",
            "Serveis",
            "Localització",
            "Contacte"
        ],
        home:{
            title: "Cuidant de la teva visió des del 1999",
            introduction_text: "Som un equip compromès amb la salut ocular, amb una atenció personalitzada i una àmplia gamma de serveis. Treballem amb les darreres tecnologies i oferim una selecció de les millors ulleres i lents de contacte del mercat. Estem aquí per ajudar-vos a cuidar de la vostra visió."
        },
        about:{
            title:"El nostre equip",
            members:[
                {
                    name: "Ingrid Vilanova",
                    img: ingrid,
                    experience_years: "més de 25 anys d'experiència",
                    title: "Diplomada en Òptica y Optometria",
                    description: "Visc i treballo a Santpedor. Vaig estudiar a la universitat politècnica de Catalunya a Terrassa. Després dels estudis vaig treballar durant 7 anys a Sant Adrià del Besós i posteriorment vaig adquirir l'Òptica de Santpedor."
                },
                {
                    name: "Loinaz Delis",
                    img: loinaz,
                    experience_years: "més de 10 anys d'experiència",
                    title: "Diplomada en Óptica y Optometría",
                    description: "Vaig estudiar a la universitat politècnica de Catalunya a Terrassa. Després vaig treballar a Corbera del Llobregat i des de fa més de 9 anys que treballo a l'Òptica de Santpedor."
                }
            ],
        },
        services:{
            title:"Els nostres serveis",
            services: [
                {
                    name:"Revisions optomètriques",
                    description:"Aquestes revisions permeten identificar problemes de la visió com miopia, hipermetropia, astigmatisme i altres anomalies oculars com problemes oculomotors. També diagnostiquem cataractes i mitjançant retinografies podem detectar altres patologies."
                },
                {
                    name:"Teràpia visual",
                    description:"L'objectiu és millorar i corregir problemes visuals relacionats amb la coordinació dels ulls, el seguiment dels objectes i altres habilitats visuals. Aquesta teràpia utilitza exercicis per ajudar a enfortir els músculs oculars, millorar la percepció visual i augmentar la comoditat i l'eficiència visual."
                },
                {
                    name:"Adaptació de lents de contacte",
                    description:"Adaptem lents de contacte per a solucionar problemes de miopia, hipermetropia, astigmatisme i presbicia."
                },
                {
                    name:"Servei d'audiología",
                    description:"Es realitzen proves d'audició per avaluar la capacitat auditiva dels pacients i identificar pèrdues auditives o altres problemes relacionats amb l'oïda. En funció dels resultats, es recomana l'ús d'aparells auditius o altres dispositius per millorar la qualitat auditiva."
                },
                {
                    name:"Assesorament personal",
                    description:"Assesorem i recomenem una solució personalitzada per les necessitats dels nostres pacients."
                }
            ]
        },
        location:{
            title:"Localització",
            description:"Ubicats en el casc antic de Santpedor, Carrer Ample 4. 08251",
            schedule: "Dilluns a divendres: <br/> 09:30 - 13:00 <br/> 17:00 - 20:30 <br/> <br/> Dissabte: <br/> 09:30 - 13:30"
        },
        contact:{
            title:"Contacta amb nosaltres",
            description:"Pots trobar-nos en les nostres xarxes socials, o si ho preferèixes, enviar-nos un mail.",
            input_1: "Introdueix el teu nom",
            input_2: "Introdueix el teu mail",
            textarea: "Missatge",
            send_button: "Enviar",
            success:"Ens posarem en contacte tant aviat com sigui possible",
            failure:"Alguna cosa ha anat malament...",
        }
    },
    ESP:{
        header:[
            "Inicio",
            "Nosotros",
            "Servicios",
            "Localización",
            "Contacto"
        ],
        home:{
            title:"Cuidando de tu visión desde el 1999",
            introduction_text: "Somos un equipo comprometido con la salud ocular, con atención personalizada y una amplia gama de servicios. Trabajamos con las últimas tecnologías y ofrecemos una selección de las mejores gafas y lentillas del mercado. Estamos aquí para ayudarle a cuidar de su visión."
        },
        about:{
            title:"Nuestro equipo",
            members:[
                {
                    name: "Ingrid Vilanova",
                    title: "Diplomada en Óptica y Optometría",
                    img: ingrid,
                    experience_years: "más de 25 años de experiencia",
                    description: "Vivo y trabajo en Santpedor. Estudié en la Universidad Politécnica de Cataluña en Terrassa. Después de los estudios trabajé durante 7 años en Sant Adrià del Besós y posteriormente adquirí la Óptica de Santpedor."
                },
                {
                    name: "Loinaz Delis",
                    img: loinaz,
                    experience_years: "más de 10 años de experiencia",
                    title: "Diplomada en Óptica y Optometría",
                    description: "Estudié en la Universidad Politécnica de Cataluña en Terrassa. Después trabajé en Corbera del Llobregat y desde hace más de 9 años que trabajo en la Óptica de Santpedor."
                }
            ],
        },
        services:{
            title: "Nuestros Servicios",
            services: [
                {
                    name: "Revisión Optométrica",
                    description: "Estas revisiones permiten identificar problemas de la visión como miopía, hipermetropía, astigmatismo y otras anomalías oculares, así como diagnosticar cataratas. También se pueden detectar otras patologías mediante retinografías."
                },
                {
                    name: "Terapia Visual",
                    description: "El objetivo es mejorar y corregir problemas visuales relacionados con la coordinación de los ojos, el seguimiento de objetos y otras habilidades visuales. Esta terapia utiliza ejercicios para fortalecer los músculos oculares, mejorar la percepción visual y aumentar la comodidad y eficiencia visual."
                },
                {
                    name: "Adaptación de Lentes de Contacto",
                    description: "Adaptamos lentes de contacto para solucionar problemas de miopía, hipermetropía, astigmatismo y presbicia."
                },
                {
                    name: "Servicio de Audiología",
                    description: "Se realizan pruebas de audición para evaluar la capacidad auditiva de los pacientes y detectar pérdidas auditivas u otros problemas relacionados con el oído. Según los resultados, se recomienda el uso de aparatos auditivos u otros dispositivos para mejorar la calidad auditiva."
                },
                {
                    name: "Asesoramiento Personalizado",
                    description: "Asesoramos y recomendamos una solución personalizada para las necesidades de nuestros pacientes."
                }
            ]
            
        },
        location:{
            title:"Localització",
            description:"Ubicados en el barrio antiguo de Santpedor, Carrer Ample 4. 08251",
            schedule: "Lunes a viernes: <br/> 09:30 - 13:00 <br/> 17:00 - 20:30 <br/> <br/> Sábado <br/> 09:30 - 13:30"
        },
        contact:{
            title:"Contacta con nosotros",
            description:"Puedes encontrarnos en nuestras redes sociales, o si lo prefieres, enviarnos un mail.",
            input_1: "Introduce tu nombre",
            input_2: "Introduce tu mail",
            textarea: "Mensaje",
            send_button: "Enviar",
            success:"Nos pondremos en contacto lo antes posible",
            failure:"Alguna cosa ha ido mal..."
        }
    },
    ENG:{
        header:[
            "Home",
            "About us",
            "Services",
            "Location",
            "Contact"
        ],
        home:{
            title:"Taking Care of Your Vision since 2000",
            introduction_text: "We are a team committed to ocular health and the well-being of the community. With personalized attention and a wide range of services, we are here to help you take care of your vision. We work with the latest technologies and offer a selection of the best glasses and contact lenses on the market. Whatever your visual needs may be, put your trust in the professionals at Poble Nou Optics. Your ocular health is our priority."
        },
        about: {
            title: "Our Team",
            members: [
                {
                    name: "Ingrid Vilanova",
                    img: ingrid,
                    experience_years: "over 25 years of experience",
                    title: "Diploma in Optics and Optometry",
                    description: "I live and work in Santpedor. I studied at the Polytechnic University of Catalonia in Terrassa. After my studies, I worked for 7 years in Sant Adrià del Besós, and later, I acquired the Santpedor Optics."
                },
                {
                    name: "Loinaz Delis",
                    img: loinaz,
                    experience_years: "over 10 years of experience",
                    title: "Diploma in Optics and Optometry",
                    description: "I studied at the Polytechnic University of Catalonia in Terrassa. Then, I worked in Corbera del Llobregat, and for over 9 years now, I have been working at the Santpedor Optics."
                }
            ]
        },        
        services:{
            title: "Our Services",
            services: [
                {
                    name: "Optometric Examination",
                    description: "These examinations allow us to identify vision problems such as myopia, hyperopia, astigmatism, and other ocular anomalies, as well as diagnose cataracts. Other pathologies can also be detected through retinography."
                },
                {
                    name: "Visual Therapy",
                    description: "The goal is to improve and correct visual problems related to eye coordination, object tracking, and other visual skills. This therapy uses exercises to strengthen eye muscles, improve visual perception, and increase visual comfort and efficiency."
                },
                {
                    name: "Contact Lens Fitting",
                    description: "We adapt contact lenses to address myopia, hyperopia, astigmatism, and presbyopia issues."
                },
                {
                    name: "Audiology Service",
                    description: "Hearing tests are performed to assess patients' auditory capacity and detect hearing loss or other ear-related problems. Depending on the results, the use of hearing aids or other devices may be recommended to improve auditory quality."
                },
                {
                    name: "Personalized Counseling",
                    description: "We provide personalized advice and recommend solutions tailored to the needs of our patients."
                }
            ]                        
        },
        location:{
            title:"Localització",
            description:"Located in the old town of Santpedor, Carrer Ample 4. 08251",
            schedule: "Monady to friday: <br/> 09:30 - 13:00 <br/> 17:00 - 20:30 <br/> <br/> Saturday <br/> 09:30 - 13:30"
        },
        contact:{
            title:"Contact us",
            description:"You can find us on our social networks, or if you prefer, send us an email.",
            input_1: "Introduce your name",
            input_2: "Introduce your email",
            textarea: "Message",
            send_button: "Send",
            success:"We will contact you as soon as possible",
            failure:"Something went wrong"
        }
    }
}

export default texts;