import React from 'react'

import texts from '../translation_texts'

import "./Location.css";

export default function Location({lang}) {

    const textsLocation = texts[lang].location;

    const createMarkup = (html) => {
        return { __html: html };
      };


  return (
    <div className='location'>
        <div className='location-iframe'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.0282662070545!2d1.8357831765106627!3d41.784604471262206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4f87d9635ac65%3A0xc0c3826392898a8!2s%C3%B3ptica%20Santpedor!5e0!3m2!1sca!2ses!4v1689457855916!5m2!1sca!2ses" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div className='location-text'>
            <h1>{textsLocation.title}</h1>
            <p id='location-description'>{textsLocation.description}</p>
            <p id="schedule" dangerouslySetInnerHTML={createMarkup(textsLocation.schedule)}></p>
        </div>
        <div className='location-iframe-responsive'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.0282662070545!2d1.8357831765106627!3d41.784604471262206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4f87d9635ac65%3A0xc0c3826392898a8!2s%C3%B3ptica%20Santpedor!5e0!3m2!1sca!2ses!4v1689457855916!5m2!1sca!2ses" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
  )
}
