import React, { useState, useEffect } from 'react'

import emailjs from '@emailjs/browser';
import texts from '../translation_texts'

import "./Contact.css";

import insta from '../assets/instagram-png.png';
import whatsapp from '../assets/whatsapp.png';

export default function Contact({lang}) {

    const contactTexts = texts[lang].contact;

    const [name, setName] = useState(contactTexts.input_1);
    const [email, setEmail] = useState(contactTexts.input_2);
    const [content, setContent] = useState(contactTexts.textarea);

    useEffect(() => {
      setName(contactTexts.input_1);
      setEmail(contactTexts.input_2);
      setContent(contactTexts.textarea);
    }, [lang]);


    const resetForm = () => {
        setName("");
        setEmail("");
        setContent("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let data = {
          form_name: name,
          to_name: 'Òptica Santpedor',
          from_email: email,
          to_email: 'opticaspd@gmail.com',
          message: content
        }
    
        
        await emailjs.send('service_gkuj1yr', 'template_r1oh78e', data, 'v9IU9poYDuCgtXOs5').then(() => {
          alert(contactTexts.success);
          resetForm();
          window.location.reload();
        }, (error) => {
          console.log(error);
          alert(contactTexts.failure);
        });
      }
    
    useEffect(()=>{
       if (name === ""){
            setName(contactTexts.input_1);
        }

        if (email === ""){
            setEmail(contactTexts.input_2);
        }

        if (content === ""){
            setContent(contactTexts.textarea);
        }   
    }, [name, email, content]);


    const handleChange = (setState) => (event) => {
      setState(event.target.value);
    }

  return (
    <div className='contact'>
        <div className='contact-text'>
            <h1>{contactTexts.title}</h1>
            <p>{contactTexts.description}</p>
            <div className='contact-images'>
                <a href='https://wa.me/34665860623' target='_blank'><img src={whatsapp} alt='whatsapp optica santpedor'/></a>
                <a href='https://www.instagram.com/opticasantpedor/' target='_blank'><img src={insta} alt='instagram optica santpedor'/></a>
            </div>
        </div>

        <div className='contact-form'>
            <form onSubmit={handleSubmit}>
                <input type='text' placeholder={name} onChange={handleChange(setName)} required/>
                <input type='email' placeholder={email} onChange={handleChange(setEmail)} required/>
                <textarea placeholder={content} onChange={handleChange(setContent)}/>
                <button type='submit'>{contactTexts.send_button}</button>
            </form>
        </div>
    </div>
  )
}
