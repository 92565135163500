import React from 'react'
import texts from '../translation_texts'
import Member from '../components/Member'

import "./About.css";

export default function About({lang}) {

    const textAbout = texts[lang].about;

  return (
    <div className="about">
        <h1>{textAbout.title}</h1>
        <div className="members">
          {
            textAbout.members.map(member => {
              return (
              <Member
                  className="member"
                  img={member.img}
                  name={member.name}
                  title={member.title}
                  experience_years={member.experience_years}
                  description={member.description}         
              ></Member>
              )
            })
          }
            
        </div>
    </div>
  )
}
