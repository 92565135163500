import React, {useState, useEffect} from 'react'
import texts from '../translation_texts'
import ImageSlider from '../components/ImageSlider';

import "./Home.css";
import i1 from '../assets/front-face.jpg'
import i2 from '../assets/3-portada-1.jpg'
import i3 from '../assets/1-13.jpg';

export default function Home({lang}) {

  const homeTexts = texts[lang].home;

  const images = [i1,i2,i3];
  
  return (
      <div className='home'>
        <div className='home-texts'>
          <h1>{homeTexts.title}</h1>
          <p>{homeTexts.introduction_text}</p>
        </div>
        <div className='home-slider'>
          <ImageSlider images={images}></ImageSlider>
        </div>
      </div>
  )
}
