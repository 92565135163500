import React, {useEffect, useState} from 'react'

import './ImageSlider.css';

import left from '../assets/left.png';
import right from '../assets/right.png';

export default function ImageSlider({images}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderSize = images.length;
    const [imageShowed, setImageShowed] = useState(images[0]);

    const handleSliderMove = (move) => {

        let nextIndex = currentIndex + move;
        setCurrentIndex(nextIndex);
        
        if (nextIndex >= sliderSize){
            setCurrentIndex(0);
        }

        if (nextIndex === -1){
            setCurrentIndex(sliderSize - 1);
        }
    }    

    useEffect(()=>{
        setImageShowed(images[currentIndex]);
    }, [currentIndex])


    useEffect(() => {
        const timer = setTimeout(() => {
          handleSliderMove(1);
        }, 5000); // Cambia de imagen automáticamente cada 5 segundos
    
        return () => {
          clearTimeout(timer);
        };
      }, [currentIndex]);
    

  return (
    <div className='slider'>
        <img className="arrows" src={left} onClick={() => handleSliderMove(-1)}></img>
        <img className='imagesSlide' src={imageShowed} alt='interior optica santpedor'></img>
        <img className="arrows" src={right} onClick={() => handleSliderMove(1)}></img>
    </div>
  )
}
