import React, { useEffect, useState } from 'react'
import texts from '../translation_texts'

import './Header.css'

import menu from "../assets/menu.png";

export default function Header({lang, handleLang}) {

    
    const [links, setLinks] = useState([]);
    const languageKeys = Object.keys(texts);
    const contentWebKeys = Object.keys(texts[lang]);

    useEffect(()=>{
        setLinks(texts[lang].header);
    }, [lang]);

    const handleLangChange = (newLang) => {
        handleLang(newLang);
    }

    const [responsiveMenu, setResponsiveMenu] = useState(false);

    const changeResponsiveMenu = () => {
        setResponsiveMenu(!responsiveMenu);
      }


    const handleCloseHeader = () =>{
        setResponsiveMenu(!responsiveMenu);
    }


  return (
    <>
        <header className='header'>
            <h1><a href='#home'>ÒPTICA SANTPEDOR</a></h1>

            <div className='links-icon'>
                <div className={`header-links${responsiveMenu ?'-active' : ''}`}>
                    <ul>
                        {
                            links.map((element, index) => {
                                return <li key={index}><a href={"#"+contentWebKeys[index+1]}>{element}</a></li>
                            })
                        }
                    </ul>
                    <select defaultValue="Cat" onChange={e => handleLangChange(e.target.value)}>
                        {
                            languageKeys.map(language => {
                                return <option value={language}>{language}</option>
                            })
                        }
                    </select>
                </div>
                <img src={menu} id="menu" onClick={changeResponsiveMenu} alt='menu optica santpedor'></img>
            </div>
        </header>
        <div className={`responsive-links${responsiveMenu ?'-active' : ''}`}>
            <ul>
                {
                    links.map((element, index) => {
                        return <li key={index} onClick={handleCloseHeader}><a href={"#"+contentWebKeys[index+1]}>{element}</a></li>
                    })
                }
                <select defaultValue="Cat" onChange={e => handleLangChange(e.target.value)}>
                {
                    languageKeys.map(language => {
                        return <option value={language}>{language}</option>
                    })
                }
                </select>
            </ul>
        </div>
    </>
  )
}
