import React, { useState } from 'react';

import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Location from './pages/Location';
import Header from './components/Header';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Footer from './components/Footer';

function App() {

  const [lang, setLang] = useState("CAT");

  const handleLang = (newLang) => {
    if (newLang !== lang){
      setLang(newLang);
    }
  }

  return (
    <div className="App">
      <div id="home"></div>
      <Header lang={lang} handleLang={handleLang}/>
      <Home lang={lang} />
      <div className='space-section' id='about'></div>
      <About lang={lang}/>
      <div className='space-section' id='services'></div>
      <Services lang={lang}/>
      <div className='space-section' id='location'></div>
      <Location lang={lang}/>
      <div className='space-section' id='contact'></div>
      <Contact lang={lang}/>
      <Footer />
    </div>
  );
}

export default App;
