import React, { useEffect, useState } from 'react'

import "./Services.css"
import texts from '../translation_texts'

export default function Services({lang}) {

    const servicesText = texts[lang].services;

    
    const handleDescription = (index) => {

      let prevElement = document.querySelector(".description-shown");
      let nextElement = document.getElementById(index);
      if (prevElement != nextElement){
        if (prevElement){
          prevElement.classList.remove("description-shown");
          prevElement.classList.add("description-hidden");
        }
  
        
        if (nextElement){
          nextElement.classList.remove("description-hidden");
          nextElement.classList.add("description-shown");
        }
      }
    }

  return (
    <div className='services'>
      <div className='services-text'>
        <h1>{servicesText.title}</h1>
      </div>

      <div className='services-services'>
        {
          servicesText.services.map((service, index) => {
            return (
              <div onClick={(e) => handleDescription(index)} className='service'>
                <h3>{service.name}</h3>
                <p className='description-hidden' id={index}>{service.description}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
